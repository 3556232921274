import request from '@/utils/request'

// 查询花房大屏套餐详情
export function findFlowerServiceOrderPackage(id) {
  return request({
    url: `/service_order_packages/${id}/flower`,
    method: 'get'
  })
}

// 隐藏花房大屏套餐
export function hideFlowerServiceOrderPackage(id) {
  return request({
    url: `/service_order_packages/${id}/hide`,
    method: 'post'
  })
}
