<template>
  <div class="custom-height-100p">
    <div class="hall-box" v-if="data && data.length > 0">
      <div
        v-for="hall in data"
        :key="hall.hall_id"
        class="hall-content-box"
      >
        <div class="custom-padding-10 custom-height-100p hall-content">
          <div
            :class="`hall-name
            ${hall.status === 'idle' ? 'hall-name-idle': hall.status === 'using' ? 'hall-name-active' : hall.status === 'hall' ? 'hall-name-hall' : hall.status === 'funeral' ? 'hall-name-funeral' : ''}` "
          >
            {{ hall.hall_name }}
          </div>

          <div v-if="hall.packages && hall.packages.length > 0" style="overflow: auto;" class="custom-margin-top-10 custom-height-100p">
            <div class="custom-height-100p">
              <div
                class="custom-card-box custom-height-100p custom-margin-bottom-10 package-info"
                :style="hall.packages.length > 1 ? 'height: 95%' : ''"
                style="padding: 15px"
                v-for="packageInfo in hall.packages"
                :key="packageInfo.service_order_package_id"
              >
                <div style="text-align: right;"><a-icon type="close" @click="hidePackage(packageInfo.service_order_package_id)" /></div>
                <div>
                  <div class="package-value">{{ packageInfo.package_name }}</div>
                  <div class="package-value">￥{{ packageInfo.price | formatCurrency }}</div>
                  <div>
                    <div>逝者姓名(性别-年龄)：</div>
                    <div class="package-value">{{ packageInfo.death_name }}({{ packageInfo.sex + '-' + packageInfo.age }})</div>
                  </div>
                  <div>
                    <div>进厅时间：</div>
                    <div class="package-value">{{ packageInfo.to_hall_time }}</div>
                  </div>
                  <div>
                    <div>出厅时间：</div>
                    <div class="package-value">{{ packageInfo.leave_hall_time }}</div>
                  </div>
                </div>
                <div style="text-align: center;">
                  <a-button class="info-btn" @click="showInfo(packageInfo.service_order_package_id)">详情</a-button>
                </div>
              </div>
            </div>
          </div>
          <no-data class="custom-card-box custom-margin-top-10" tip="暂无套餐数据" v-else />
        </div>
      </div>
    </div>
    <no-data class="custom-card-box" v-else />
    <Info
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />
  </div>
</template>

<script>
import { findFlowerHalls } from '@/api/hall'
import { hideFlowerServiceOrderPackage } from '@/api/service_order_package'
import NoData from '@/views/home/NoData'

export default {
  name: 'DispatchScreenHome',
  components: {
    NoData,
    Info: () => import('@/views/home/dispatch_screen/Show')
  },
  data() {
    return {
      data: [],
      isShowInfoModal: false,
      showingId: 0
    }
  },
  created() {
    this.fetchData()
    this.loopFetchData()
  },
  destroyed() {
    clearInterval(this.loopFetchDataTimer)
  },
  methods: {
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, 60000)
    },

    fetchData() {
      findFlowerHalls().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    showInfo(id) {
      this.isShowInfoModal = true
      this.showingId = id
    },

    hidePackage(packageId) {
      const vm = this
      this.$confirm({
        title: '确定隐藏吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          hideFlowerServiceOrderPackage(packageId).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.hall-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;

  .hall-content-box {
    flex-basis: 14.28%;
    height: 50%;

    .hall-content {
      display: flex;
      flex-direction: column;
    }

    .hall-name {
      padding: 10px;
      border: 2px solid rgba(206, 123, 58, 100%);
      border-radius: 6px;
      text-align: center;
      background: rgba(170, 170, 170, 100%);
    }

    .hall-name-active {
      background: rgba(206, 123, 58, 100%);
    }

    .hall-name-idle {
      background: #409373;
    }

    .hall-name-hall {
      background: rgb(255, 244, 102);
      color: black;
    }

    .hall-name-funeral {
      background: rgb(252, 0, 0);
    }

    .package-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .package-value {
        font-size: 20px;
        color: #f59a23;
      }
    }

    .info-btn {
      border: none;
      background-color: #ffd794ff;
    }
  }
}
</style>

